import PropTypes from "prop-types";
import React, { useState } from "react";
import { useLanderConfig } from "../commons/ConfigContext";
import { QueryParam } from "../commons/Constants";
import { getVerizonAdsUri } from "./verizonFeed";

export const SearchBox = ({ xmlResult }) => {
  const config = useLanderConfig();
  const rootDomain = config.landerConfig.domain.rootDomain;
  const [inputText, setInputText] = useState("");

  const queryParam =
    xmlResult?.getElementById("searchBox")?.getAttribute("queryParams") ?? "";

  if (
    config.landerConfig.lander.template.includes("PARK_WEB") || // dont show search for parkweb and heg
    !config.queryConfig[QueryParam.SEARCH_BOX] ||
    !queryParam
  )
    return null;

  return (
    <div id="searchbox">
      <label>
        <input
          type="text"
          value={inputText}
          onChange={(event) => setInputText(event.target.value)}
        />
      </label>
      <a href={getVerizonAdsUri(queryParam, rootDomain, inputText)}>
        <button>Search Ads</button>
      </a>
    </div>
  );
};
SearchBox.propTypes = {
  xmlResult: PropTypes.object,
};
