import React from "react";
import CssRule, { cssRule, cssRules } from "../../commons/CssRule";
import { GoogleCaf } from "../utils/adSenseUtils";
import background from "./LightBlueBackground.png";

export function getLightBluePageLevelParams() {
  return {
    uiOptimize: false,
    styleId: GoogleCaf.styleId.LIGHT_BLUE,
  };
}

export function getLightBlueSearchbox(mobile) {
  const obj = {
    radiusSearchInputBorder: "3", // Use this parameter to add rounded borders to the search input field AND the search button.
    heightSearchInput: "30",
    heightSearchButton: "32",
    fontSizeSearchInput: "14",
    fontSizeSearchButton: "14",
    hideSearchButtonBorder: false,
    hideSearchInputBorder: true,
    colorSearchButton: "#E8EAEB",
    colorSearchButtonBorder: "#AAB7C2",
    colorSearchButtonText: "#2b2b2b",
    widthSearchInput: "180",
    widthSearchButton: "100",
    widthSearchButtonBorder: "1",
  };
  if (mobile) {
    obj.widthSearchInput = "180";
  }
  return obj;
}

export function getLightBlueRelatedLinks(mobile) {
  const obj = {
    // Attribution (Related Links text):
    webFontFamilyAttribution: "Helvetica",
    fontFamilyAttribution: "verdana",
    colorAttribution: "#2b2b2b",
    fontSizeAttribution: "17px",
    attributionSpacingBelow: 20, // max is 40
    attributionUppercase: false,
    attributionBold: false,
    // Block:
    colorBackground: "transparent",
    width: "700px",
    // Links:
    titleBold: true,
    webFontFamily: "Helvetica",
    fontFamily: "verdana",
    fontSizeTitle: 22,
    verticalSpacing: 23, // max is 24
    lineHeightTitle: 50, // max is 50
    noTitleUnderline: true,
    colorTitleLink: "#2b2b2b",
    rolloverLinkUnderline: true,
    columns: 1,
  };
  if (mobile) {
    obj.width = null;
  }
  return obj;
}

export function getLightBlueAds() {
  return {
    linkTarget: "_blank",
  };
}

export function commonRules() {
  return cssRules([
    new CssRule("body", [
      "margin: 0;",
      "padding: 0;",
      "background-color: #EEF6FF;",
      "font-family: 'Arial', serif;",
    ]),
    new CssRule("a", ["text-decoration: none;"]),
    new CssRule("#related_links", [
      "border-radius: 5px;",
      "background-color: #EEF6FF;",
      "padding: 30px;",
      "padding-bottom: 10px;",
      "padding-top: 10px;",
    ]),
    new CssRule("#tdfsBanner", [
      "background: white;",
      "font-size: 23px;",
      "padding-top: 16px;",
      "padding-bottom: 16px;",
      "-webkit-transition: padding 400ms;",
      "transition: padding 400ms;",
    ]),
    new CssRule("#tdfsBanner:hover", ["padding: .7em .5em;"]),
    new CssRule("#tdfsLink", [
      "margin:0 auto;",
      "max-width: 960px;",
      "width: 95%;",
      "text-align: center;",
      "font-family: Helvetica;",
      "font-size: 16px;",
    ]),
    new CssRule("#tdfsLink a", [
      "color: #000;",
      "display: block;",
      "font-size: 1em;",
      "font-weight: 300;",
      "text-decoration: none;",
      "line-height: 1.5em;",
    ]),
    new CssRule("#contentLayout > #related_links", ["order: 1;"]),
    new CssRule("#contentLayout > #searchbox", ["order: 2;"]),
    new CssRule("#contentLayout > #footer", ["order: 3;"]),
    new CssRule("#domain", [
      "margin-left:0px;",
      "margin-bottom:10px;",
      "font-size:26px;",
      "color:#000;",
    ]),
    new CssRule("#searchbox", [
      "margin: auto;",
      "font-weight: normal;",
      "text-align: center;",
      "height: 24px;",
    ]),
    new CssRule("#sponsored_listings", [
      "border-radius: 5px;",
      "background-color: #EEF6FF;",
      "width: 100%;",
      "padding: 30px;",
      "padding-top: 10px;",
    ]),
    new CssRule("#footer", ["width: auto;", "padding-top: 70px;"]),
    new CssRule("#copyright", [
      "padding: 5px;",
      "font-size: 11px;",
      "text-align: center;",
    ]),
    new CssRule("#privacy", [
      "padding: 5px;",
      "font-size: 11px;",
      "text-align: center;",
    ]),
    new CssRule("#privacy a", ["text-decoration: none;", "color:#2b2b2b;"]),
    new CssRule("#footerElement, #HeaderElement", [
      "padding: 10px;",
      "color: #2b2b2b;",
      "font-size: 11px;",
      "text-align: center;",
    ]),
    new CssRule(".footerLine", [
      "line-height: 25px;",
      "font-size: 11px;",
      "text-align: center;",
    ]),
    new CssRule(".footerColor", ["color:#2b2b2b;"]),
    new CssRule(".highlight", ["color: #FD7400;"]),
  ]);
}

export function LightBlue() {
  return (
    <style>
      {commonRules()}
      {cssRule("#related_links", [
        "background-image: url(" + background + ");",
        "background-position: top left;",
        "background-repeat: no-repeat;",
        "background-position-x: 0px;",
        "background-position-y: 40px;",
        "background-size: auto 765px;",
      ])}
      {cssRule("#contentLayout", [
        "margin: auto;",
        "width: 75%;",
        "min-width: 600px;",
        "max-width: 700px;",
        "padding: 10px;",
        "display: flex;",
        "flex-direction: column;",
      ])}
      {cssRule("#searchbox", ["padding-top: 20px;"])}
    </style>
  );
}

export function LightBlueMobile() {
  return (
    <style>
      {commonRules()}
      {cssRule("#contentLayout", [
        "margin: auto;",
        "display: flex;",
        "flex-direction: column;",
      ])}
      {cssRule("#searchbox", ["width: 80%;"])}
      {cssRule("#tdfsLink", ["line-height: 35px"])}
      {cssRule("#footer", ["width: auto;", "padding-top: 20px;"])}
    </style>
  );
}
