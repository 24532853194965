import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";
import { mergeTerms } from "./configMergeUtils";

const ConfigContext = createContext({
  landerConfig: {},
  queryConfig: {},
  mergedConfig: {},
});

export const useLanderConfig = () => {
  return useContext(ConfigContext);
};

export const LanderConfigProvider = ({
  landerConfig,
  queryConfig,
  children,
}) => {
  const mergedConfig = {
    keywords: mergeTerms(landerConfig, queryConfig),
  };

  const contextValue = {
    landerConfig: landerConfig,
    queryConfig: queryConfig,
    mergedConfig: mergedConfig,
  };

  return (
    <ConfigContext.Provider value={contextValue}>
      {children}
    </ConfigContext.Provider>
  );
};
LanderConfigProvider.propTypes = {
  landerConfig: PropTypes.object.isRequired,
  queryConfig: PropTypes.object.isRequired,
  children: PropTypes.element,
};
