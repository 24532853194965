import React from "react";
import { getVerizonAdsUri } from "./verizonFeed";
import { toTitleCase } from "../commons/HelperFunctions";
import { useLanderConfig } from "../commons/ConfigContext";
import PropTypes from "prop-types";

export const VerizonRS = ({ xmlResult }) => {
  const config = useLanderConfig();
  const rootDomain = config.landerConfig.domain.rootDomain;
  const resultSet =
    xmlResult?.getElementById("relatedTerms")?.getElementsByTagName("Search") ??
    [];
  const verizonFeed = Array.from(resultSet);

  return (
    <div className="verizon-rs">
      <div
        style={{
          fontSize: "small",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <span>Related Search Topics</span>
        <span>Ads</span>
      </div>
      <ul
        style={{
          listStyle: "none",
          textAlign: "center",
          padding: "12px",
          margin: 0,
        }}
      >
        {
          // create related link items
          verizonFeed &&
            verizonFeed.map((item, itemIndex) => {
              return (
                <li
                  key={`verizon-feed-item-${itemIndex}`}
                  style={{ padding: "1em" }}
                >
                  <a
                    href={getVerizonAdsUri(
                      item.getAttribute("queryParams"),
                      rootDomain
                    )}
                    style={{ fontSize: "28px" }}
                  >
                    {toTitleCase(item.innerHTML)}
                  </a>
                </li>
              );
            })
        }
      </ul>
    </div>
  );
};

VerizonRS.propTypes = {
  xmlResult: PropTypes.object,
};
