import React, { useRef } from "react";
import LayoutStyle, { SimpleLayoutBackground } from "./LayoutStyle";
import PropTypes from "prop-types";
import RectHtmlParser from "html-react-parser";
import { AdSense } from "../utils/adSenseUtils";
import { Settings } from "../../config";
import { QueryParam, System } from "../../commons/Constants";
import Logger from "../../commons/Logger";
import { Banner } from "../../banner/Banner";
import { FormattedMessage } from "react-intl";
import { AdContent } from "../AdContent";
import { ArrowUIWrapper } from "./ArrowUIWrapper";
import useNoWrapFontStyle from "../../commons/useNoWrapFontStyle";

const SimpleLayout = ({ landerConfig, queryConfig, page, content }) => {
  // banner
  const bannerContent = (
    <Banner
      landerConfig={landerConfig}
      queryConfig={queryConfig}
      content={content}
    />
  );

  let domain;

  // default is false for this template
  const domainName =
    landerConfig.lander.domainDisplayName || landerConfig.domain.rootDomain;

  if (
    typeof queryConfig[QueryParam.SHOW_DOMAIN] === "boolean"
      ? queryConfig[QueryParam.SHOW_DOMAIN]
      : landerConfig.lander.showDomain
  ) {
    domain = <Domain domain={domainName} />;
  } else {
    domain = <Domain domain="" />;
  }

  let adContainerId = "related_links";
  if (page === AdSense.page.SPONSORED_LISTINGS) {
    adContainerId = "sponsored_listings";
  }

  let headerElement;
  if (landerConfig.lander.headerHtml) {
    let decodedHtml = "";
    try {
      decodedHtml = atob(landerConfig.lander.headerHtml);
    } catch (e) {
      Logger.error("headerHtml value is not properly encoded" + toString(e));
    }
    headerElement = <HeaderHtmlElement headerHtml={decodedHtml} />;
  } else if (landerConfig.lander.headerText) {
    headerElement = (
      <HeaderTextElement headerText={landerConfig.lander.headerText} />
    );
  }

  return (
    <div id="simpleLayout">
      {bannerContent}
      <SimpleLayoutBackground template={landerConfig.lander.template} />
      <div id="contentLayout">
        <LayoutStyle template={landerConfig.lander.template} />

        {headerElement}
        {domain}
        <ArrowUIWrapper template={landerConfig.lander.template}>
          <div id={adContainerId}>
            <AdContent
              landerConfig={landerConfig}
              queryConfig={queryConfig}
              page={page}
            />
          </div>
        </ArrowUIWrapper>
        <Footer landerConfig={landerConfig} queryConfig={queryConfig} />
      </div>
    </div>
  );
};

SimpleLayout.propTypes = {
  page: PropTypes.string.isRequired,
  landerConfig: PropTypes.object.isRequired,
  queryConfig: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
};

export default SimpleLayout;

export function HeaderHtmlElement({ headerHtml }) {
  return <div id="headerElement">{RectHtmlParser(headerHtml)}</div>;
}

export function HeaderTextElement({ headerText }) {
  return <div id="headerElement">{headerText}</div>;
}

export function Domain({ domain }) {
  const domainContainerRef = useRef(null);
  const fontStyle = useNoWrapFontStyle(
    domain,
    domainContainerRef,
    Settings.NORMAL_DOMAIN_LENGTH_LIMIT
  );

  return (
    <div ref={domainContainerRef}>
      <div style={fontStyle} id="domain">
        {domain}
      </div>
    </div>
  );
}

export function Footer({ landerConfig, queryConfig }) {
  let disclaimer;
  const isParkWeb =
    landerConfig.system === System.PARKWEB && !landerConfig.turnKeyReseller;
  if (isParkWeb) {
    disclaimer = (
      <div className="footerLine footerColor">
        <Disclaimer />
      </div>
    );
  }

  let footerElement;
  if (landerConfig.lander.footerHtml) {
    let decodedHtml = "";
    try {
      decodedHtml = atob(landerConfig.lander.footerHtml);
    } catch (e) {
      Logger.error("footerHtml value is not properly encoded" + toString(e));
    }
    footerElement = (
      <div className="footerLine footerColor">
        <FooterHtmlElement footerHtml={decodedHtml} />
      </div>
    );
  } else if (landerConfig.lander.footerText) {
    footerElement = (
      <div className="footerLine footerColor">
        <FooterTextElement footerText={landerConfig.lander.footerText} />
      </div>
    );
  }

  return (
    <div id="footer">
      {footerElement}
      <div className="footerLine footerColor">
        <Copyright landerConfig={landerConfig} queryConfig={queryConfig} />{" "}
        &nbsp; | &nbsp; <Privacy landerConfig={landerConfig} />
      </div>
      {disclaimer}
    </div>
  );
}

export function FooterHtmlElement({ footerHtml }) {
  return <div id="footerElement">{RectHtmlParser(footerHtml)}</div>;
}

export function FooterTextElement({ footerText }) {
  return <div id="footerElement">{footerText}</div>;
}

export function Copyright({ landerConfig, queryConfig }) {
  const showDomainName =
    queryConfig[QueryParam.SHOW_DOMAIN] ||
    landerConfig.system !== System.PARKWEB;
  if (showDomainName) {
    // default is false for this template
    const domainName =
      landerConfig.lander.domainDisplayName || landerConfig.domain.rootDomain;
    return (
      <span id="copyright">
        Copyright &copy; {domainName}. &nbsp;All rights reserved.
      </span>
    );
  }
  return (
    <span id="copyright">Copyright &copy; &nbsp;All rights reserved.</span>
  );
}

export function Privacy({ landerConfig }) {
  const privacyUrl =
    (landerConfig.turnKeyReseller || {}).privacyUrl ||
    Settings.PRIVACY_POLICY_URL;

  function getModalPrivacy() {
    window.open(
      privacyUrl,
      "privacy",
      "width=520,height=400,left=250,top=250,menubar=no,status=yes,toolbar=no,scrollbars=yes,resizable=yes"
    );
  }
  if (landerConfig.system === "PW")
    return (
      <span id="privacy">
        <a href="#!" rel="nofollow" onClick={getModalPrivacy}>
          Privacy Policy
        </a>
      </span>
    );
  return (
    <span id="privacy">
      <a href="#!" rel="nofollow" onClick={getModalPrivacy}>
        <FormattedMessage id="privacyPolicyText" />
      </a>
    </span>
  );
}

export function Disclaimer() {
  return (
    <span id="disclaimer">
      This Web page is parked <span className="highlight">FREE</span>, courtesy
      of GoDaddy
    </span>
  );
}

Domain.propTypes = {
  domain: PropTypes.string.isRequired,
};

Copyright.propTypes = {
  landerConfig: PropTypes.object.isRequired,
  queryConfig: PropTypes.object.isRequired,
};

Privacy.propTypes = {
  landerConfig: PropTypes.object.isRequired,
};

HeaderHtmlElement.propTypes = {
  headerHtml: PropTypes.string.isRequired,
};

HeaderTextElement.propTypes = {
  headerText: PropTypes.string.isRequired,
};

FooterHtmlElement.propTypes = {
  footerHtml: PropTypes.string.isRequired,
};

FooterTextElement.propTypes = {
  footerText: PropTypes.string.isRequired,
};

Footer.propTypes = {
  landerConfig: PropTypes.object.isRequired,
  queryConfig: PropTypes.object.isRequired,
};
