import React, { useEffect, useState } from "react";
import { FetchStatus } from "../commons/Constants";
import {
  verizonXmlFetch,
  getVerizonRSUri,
  sendVerizonEvent,
} from "./verizonFeed";
import { useLanderConfig } from "../commons/ConfigContext";
import { VerizonAds } from "./VerizonAds";
import { VerizonRS } from "./VerizonRS";
import { SearchBox } from "./SearchBox";
import { FormattedMessage } from "react-intl";

export const VerizonContent = () => {
  const config = useLanderConfig();
  const [fetchState, setFetchState] = useState(FetchStatus.PENDING);
  const [xmlResult, setXmlResult] = useState(null);

  const isAdClicked = new URLSearchParams(window.location.search).has("qs");

  useEffect(() => {
    let verizonUrl;
    // forward query params from browser url
    if (isAdClicked) verizonUrl = window.location.search;
    else
      verizonUrl = getVerizonRSUri(
        config.landerConfig.domain.rootDomain,
        config.mergedConfig.keywords,
        config.landerConfig.system
      );

    verizonXmlFetch(verizonUrl).then((v) => {
      setFetchState(v.fetchStatus);
      setXmlResult(v.xmlResult);
      sendVerizonEvent(v.message, isAdClicked, config.landerConfig);
    });
  }, [
    config.landerConfig,
    config.landerConfig.domain.rootDomain,
    config.mergedConfig.keywords,
    isAdClicked,
  ]);

  let verizonContent;
  if (fetchState === FetchStatus.PENDING) {
    verizonContent = null;
  } else if (fetchState === FetchStatus.SUCCESS) {
    verizonContent = (
      <>
        {isAdClicked ? (
          <VerizonAds xmlResult={xmlResult} />
        ) : (
          <VerizonRS xmlResult={xmlResult} />
        )}
        <SearchBox xmlResult={xmlResult} />
      </>
    );
  } else {
    verizonContent = <FormattedMessage id="adsBlockedText" />;
  }

  return <div id="verizon-feed">{verizonContent}</div>;
};
